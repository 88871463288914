<template>
    <div class="app-features">
        <h1 class="font-bold text-orange">{{features.title}}</h1>
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="feature-bg">
                    <img src="assets/images/backgrounds/feature2.png"/>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="section-content">
                    <ul>
                        <li v-for="(section,index) in features.sections" :key="index">
                            <div class="d-flex align-items-center">
                                <span class="enjoy-icon">
                                    <img :src='"/assets/images/icons/"+section.icon+".png"'/>
                                </span>
                                <p>{{section.text}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            features: {
                title: 'خصائص مختصر',
                sections: [
                    {icon: 'feature4', text: ' اقرأ بالوضع الليلي '},
                    {icon: 'feature5', text: ' حمل مختصرك المفضل واستمع له بدون انترنت'},
                    {icon: 'feature6', text: 'تحكم في سرعة الصوت المناسبة لك'},
                    {icon: 'feature7', text: 'أحصل على تحديثات مستمره'},
                ]
            },
        }
    },
}
</script>
<style lang="scss" scoped>
.app-features {
    margin: 0 31px 32px 37px;
    h1 {
        font-size: 16px;
        line-height: 30px;
        text-align: right;
        letter-spacing: 0.01em;
        margin-bottom: 18px; 
    }
    .feature-bg {
        text-align: center;
        img {
            height: 217px;
        }
    }
    ul {
		list-style: none;
		text-align: right;
        color: #1D293F;
        padding-right: 0;
        .enjoy-icon {
			display: inline-block;
			width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 10px;
			background: #FFFFFF;
			border: 1px solid #E2E4E8;
			box-sizing: border-box;
			margin-left: auto;
			vertical-align: middle;
			text-align: center;
			img {
                max-width: 76%;
                margin: auto;
			}
        }
        li {
            margin-bottom: 15px;
			p {
				padding-right: 7px;
                font-size: 14px;
                line-height: 25px;
				display: inline-block;
				width: 80%;
				text-align: right;
				margin-left: auto;
				margin-bottom: 0;
			}
		}
    }
}
</style>