<template>
    <div>
        <div class="offer-box text-center bg-gray">
            <div class="icon">
                <img src="/assets/images/icons/best.png"/>
            </div>
            <h2 class="font-bold text-orange">
                {{title}}
            </h2>
            <p class='text-center'>
                {{p}}
            </p>
            <div class="forever-btn cursor-pointer text-center" @click="$emit('handleUpsell')">
                <span class="font-bold"> احصل الان على مختصر</span>
                <br/>
                ( مدى الحياة )
            </div>
            <a class="reject cursor-pointer" @click="goToConfirmaePage">
                <span> لا شكرآ، </span>
                    لا أرغب في الاشتراك في مختصر مدى الحياة
            </a>
        </div>
        <p class="description">
            {{description}}
        </p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '"مبروك!!!',
            p: ' لقد تم ترشيحك ضمن ١٠٠ شخص محضوظ للحصول على تطبيق مختصر مدى الحياة مقابل 739 ر.س فقط. ',
            description: ' بتفعيل هذا العرض ستتمكن من استخدام تطبيق مختصر مدى الحياة بدون الحاجة لدفع رسوم اشتراك شهرية او سنوية في المستقبل ستدفع فقط 739 ر.س ولمرة واحدة. '
        }
    },
    methods: {
        goToConfirmaePage() {
            this.$router.push({path: '/confirmation-page', query: {status: 'success'}})
        },
      
    }
}
</script>
<style lang="scss" scoped>
.offer-box {
    border-radius: 5px;
    margin: 0 31px 52px;
    padding: 15px 0 32px;
    color: #000000;
    h2 {
        font-size: 18px;
        line-height: 34px;
        letter-spacing: 0.01em;
        margin-bottom: 4px;
    }
    p {
        font-size: 16px;
        line-height: 27px;
        margin: 8px 21px 8px 16px;
    }
    .forever-btn {
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        background: linear-gradient(0deg, #394149, #394149), #16464D;
        border-radius: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        margin: 0 22px 12px 19px;
        padding-top: 8px;
        padding-bottom: 2px;
    }
    a.reject {
        font-family: "NotoKufiArabic-Regular";
        font-size: 12px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #000000;
        span {
            color: #FF7700;
        }
    }
}
p.description {
    margin: 0 31px 20px 52px ;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
}
</style>