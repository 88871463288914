<template>
    <div class="sellup-page">
        <offer-section @handleUpsell='upsell'/>
        <features-section/>
        <package-features/>
        <FAQ-section/>
        <section5/>
        <reviews :client_reviews='client_reviews'/>
        <div class="sub-forever cursor-pointer" @click='upsell'>
            انضم الآن لمختصر مدى الحياة  
        </div>
        <div class="text-center">
            <a class="reject cursor-pointer" @click="goToConfirmaePage">
                <span> لا شكرآ، </span>
                    لا أرغب في الاشتراك في مختصر مدى الحياة
            </a>
        </div>
        <div class="payments-logo d-flex justify-content-between">
             <div class="payment-logo">
                <img src='/assets/images/icons/visa.png'/>
            </div>
            <div class="payment-logo">
                <img src='/assets/images/icons/mada.png'/>
            </div>
            <div class="payment-logo">
                <img src='/assets/images/icons/mastercard.png'/>
            </div>
            <div class="payment-logo">
                <img src='/assets/images/icons/XMLID.png'/>
            </div>
        </div>
        <loader v-if="loading"/>
    </div>
</template>
<script>
import OfferSection from './components/OfferSection'
import FeaturesSection from './components/FeaturesSection'
import PackageFeatures from './components/PackageFeatures'
import FAQSection from './components/FAQSection'
import Section5 from './components/section5'
import Reviews from '../register-pay/components/Reviews'
import loader from '@/components/ui/loader'

export default {
    components: {
        OfferSection,
        FeaturesSection,
        PackageFeatures,
        FAQSection,
        Section5,
        Reviews,
        loader
    },
    data() {
        return {
            client_reviews: [],
            loading: false
        }
    },
    methods: {
        async getData() {
           // const res = await this.$store.dispatch('getHomeInfo');
            if ( res.data.success ) {
                this.client_reviews = res.data.data.client_reviews.splice(0,3)
            }
        },
        goToConfirmaePage() {
            this.$router.push({path: '/confirmation-page', query: {status: 'success'}})
        },
        async upsell() {
            this.$router.push({path: '/order-summary'})
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
<style lang="scss" scoped>
.sellup-page {
    max-width: 50%;
    margin: auto;
    @media (max-width: 700px) {
        max-width: 100%;
    }
    direction: rtl;
    background: #EAEBEE;
    padding: 36px 0 76px;
    .sub-forever {
        background: linear-gradient(90deg, #FF7700 0%, #F49624 100%);
        border-radius: 8px;
        padding: 17px 0;
        text-align: center;
        font-family: "NotoKufiArabic-Bold";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        margin: 0 24px 16px 15px;
    }
    a.reject {
        font-family: "NotoKufiArabic-Bold";
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        text-align: right;
        display: inline-block;
        text-decoration-line: underline;
        color: #000000;
        span {
            color: #FF7700;
        }
        margin-left: 35px;
        margin-right: 37px;
        margin-bottom: 15px;
    }
    .payment-logo {
        width: 64px;
        height: 36px;
        left: 269px;
        top: 1638px;
        background: #DFE3E8;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

</style>