<template>
    <div class="questions">
        <h2 class="font-bold text-orange">الأسئلة الأكثر شيوعا</h2>

        <div>
            <template v-for="question in faqs">
                <Collapse :key="question.question">
                    <template v-slot:header>
                    <div class="q-item">
                            {{question.question}}  
                        </div>
                    </template>
                    <template v-slot:content>
                        <div class="answer">
                            {{question.answer}}  
                        </div>
                    </template>
                </Collapse>
            </template>
        </div>
    </div>
</template>
<script>
import Collapse from '@/components/ui/Collapse'
export default {
    components: {
        Collapse
    },
    data() {
        return {
            isShow: false,
            faqs: [
                {
                    question: 'لماذا تم ترشيحي لهذا العرض؟',
                    answer: 'هذا العرض المميز جدا هو احتفالا باطلاق مختصر ولاننا لانستطيع تقديمه للكل قررنا اهداؤه الى ١٠٠ عميل محظوظ بشكل عشوائي.'
                },

                {
                    question: 'هل بعد تفعيلي لهذا العرض سأتمكن من الحصول على كل مزايا مختصر المدفوعة مدى الحياة؟',
                    answer: 'نعم ستحصل على كل المزايا وكل التحديثات المستقبلية بشكل مجاني.'
                },
                {
                    question: 'هل بامكاني تغيير رأي بعد الانضمام الى هذا العرض؟',
                    answer: 'لانرى انه يمكن ان يوجد سبب يجعلك تستغني عن هذه الفرصة الثمينة ولكن في حال قمت بتغيير رأيك خلال مدة اقصاها ٧ ايام من الانضمام بامكانك التواصل معنا لالغاء العرض.'
                },
                {
                    question: 'هل يمكنني الانضمام الى هذا العرض لاحقا؟',
                    answer: 'للاسف هذا العرض متوفر فقط ل ١٠٠ عضو ولن تستطيع الحصول عليه مره اخرى في حال لم تقوم بتفعيله الان.'
                },
                {
                    question: 'هل يوجد حد المختصرات التي يمكنني قراءتها او استماعها؟',
                    answer: 'لايوجد حد ويمكنك الاستفادة من كل المختصرات مدى الحياة.'
                },
            ]
        }
    }
}
</script>
<style>
.questions {
    padding: 33px 30px 0;
}
.questions h2 {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    max-width: 46%;
    margin: auto;
    margin-bottom: 28px;
}
.questions .item {
    margin-bottom: 22px;
}
.questions .q-item {
    font-size: 12px;
    line-height: 25px;

    text-align: right;

    color: #1D293F;
    margin-bottom: 4px;

}
.questions .answer {
    font-size: 12px;
    line-height: 25px;
    text-align: right;
    color: #1D293F;
}

</style>