<template>
    <div class="collapse d-flex show">
        <div class="icon flex-row cursor-pointer" @click="show=!show;">
            <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="show">
                <path d="M2 2H14" stroke="#FF7700" stroke-width="3" stroke-linecap="round"/>
            </svg>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!show">
                <path d="M2 8H14" stroke="#FF7700" stroke-width="3" stroke-linecap="round"/>
                <path d="M8 2V14" stroke="#FF7700" stroke-width="3" stroke-linecap="round"/>
            </svg>
        </div>
        <div class="flex-row">
        <div @click="show=!show;" class="collapse-header font-bold cursor-pointer">
            <slot name="header"></slot>
        </div>
            <transition name="slide">
                <div v-if="show" class="collapse-content">
                    <slot name="content"></slot>
                </div>
            </transition>
        </div>
  </div>
</template>
<script>
export default {
    name: "Collapse",
    data() {
        return {
            show: false,
        }
    },
}
</script>
<style lang="scss">


.collapse-header {
  cursor: pointer;
}
.collapse .icon svg{
    margin-left: 9px;
}
.collapse {
    margin-bottom: 22px;
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>