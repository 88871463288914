<template>
    <div class="package-features bg-gray">
        <h2 class="font-bold">
            باقة مختصر 
            <span class="text-orange">المميزة</span>
            تتيح لك تعلم بلا حدود
        </h2>
        <table>
            <colgroup>
                <col span="1" style="width: 50%;">
                <col span="1" style="width: 25%;">
                <col span="1" style="width: 25%;">
            </colgroup>
            <thead>
                <th></th>
                <th>
                    المميزة
                    <br>
                    <span>
                        ( شهري، سنوي، مدى الحياة )
                    </span>
                </th>
                <th>
                    مجانية
                </th>
            </thead>
            <tbody>
                <tr v-for="feature in package_features" :key="feature.title">
                    <td>{{feature.title}}</td>
                    <td>
                        <template v-if="typeof feature.special == 'boolean'">
                            <img src="/assets/images/icons/check.png" v-if='feature.special'/>
                            <img src="/assets/images/icons/close.png" v-else/>
                        </template>
                        <template v-else>
                            {{feature.special}}
                        </template>
                    </td>
                    <td>
                        <template v-if="typeof feature.free == 'boolean'">
                            <img src="/assets/images/icons/check.png" v-if='feature.free'/>
                            <img src="/assets/images/icons/close.png" v-else/>
                        </template>
                        <template v-else>
                            {{feature.free}}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            package_features: [
                {
                    title: 'مختصرات مجانية محدودة',
                    special: 'غير محدودة',
                    free: 'محدودة'
                },
                {
                    title: 'استماع وقراءة لجميع المختصرات',
                    special: true,
                    free: false
                },
                {
                    title: 'مختصرات جديدة',
                    special: true,
                    free: false
                },
                {
                    title: 'قراءة أو استماع بدون انترنت',
                    special: true,
                    free: false
                },
                {
                    title: 'جميع مصنفات مختصر',
                    special: true,
                    free: false
                },


            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.package-features {
    border-radius: 20px;
    padding-top: 18px;
    padding-bottom: 20px;
    h2 {
        max-width: 68%;
        margin: auto;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #1E2D34;
        margin-bottom: 11px;
    }
}
table {
    width: 100%;
}
table th:nth-child(2) {
    color: #FF7700;
    background: rgba(255, 120, 2, 0.07);
    border: 1px solid rgba(255, 120, 2, 0.07);
    border-bottom-width: 0 ;
}

table th {
    padding-top: 18px;
    text-align: center;
    vertical-align: top;
    font-size: 14px;
    font-weight: normal;
}
table th span {
    font-size: 10px;
    line-height: 16px;
    color: #8D8D8D;
}
table th :not(span) {
     font-family: "NotoKufiArabic-Bold";
}
table tr:first-child td:not(:first-child) {
   font-size: 12px;
}
table td {
    text-align: center;
    border-bottom: 1px solid rgba(255, 120, 2, 0.07);
    padding: 15px 0;
    font-size: 10px;
    line-height: 18px;
    color: #1E2D34;
}
table td:first-child {
    padding-right: 32px;
}
table tr:last-child td {
    border-bottom-width: 0
}
table tr:last-child td:nth-child(2) {
    border-bottom-width: 0
}
table td:first-child {
    text-align: right;
}
table td:nth-child(2) {
    background: rgba(255, 120, 2, 0.07);
    border-top: 0;
}
</style>